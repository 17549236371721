<template>
  <div class="container" style="z-index: 99">
    <h1 class="neonText">
      404 Error
    </h1>
    <h2 class="neonText2">Page does not exist</h2>
  </div>

</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

.neonText {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
  font-size: clamp(40px, 10rem, 120px);
}

.neonText2 {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
  font-size: clamp(40px, 2rem, 80px);
}

h1 {
  font-size: 4.2rem;
}

/* Flickering animation */
@keyframes flicker {

  0%, 18%, 22%, 25%, 53%, 57%, 100% {

    text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #00ff95,
        0 0 80px #00ff95,
        0 0 90px #00ff95,
        0 0 100px #00ff95,
        0 0 150px #00ff95;

  }

  20%, 24%, 55% {
    text-shadow: none;
  }
}


/* Additional styling */

h2 {
  font-size: 1.8rem;
}
.container {
  margin-top: 20vh;
}

body {
  font-size: 18px;
  font-family: "Helvetica Neue", sans-serif;
  background-color: #010a01;
}

h1, h2, a {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  z-index: 100 !important;

}
</style>