<template>
  <v-alert :value="toggle" style="overflow: hidden;" min-width="350px"  class="alert_reminder label_text snackbar_s" color="#6441a5" max-width="22vw"  type="info" transition="slide-x-reverse-transition">
    <template v-slot:prepend>
      <v-img style="box-shadow: 3px 3px 0px 0 #212121;" max-width="60px" alt="streamer-avatar" :src="profile"></v-img>
    </template>
    <div class="text-center white--text">
      <a  :href="'https://twitch.tv/'+ urlEnd" target="_blank" >twitch.tv/{{urlEnd}}<v-icon >mdi-open-in-new</v-icon>
      </a>
    </div>
    <div class="animation loader"></div>
  </v-alert>
</template>

<script>
let x
export default {
  name: "ttv_card",
  mounted() {
    setTimeout( () => {
      this.toggle = false
    },15965)
  },

  props: {
    urlEnd: String,
    profile: String,
  },

  data: () => ({
    toggle: true,
    timer: '5s',
  }),
}
</script>

<style scoped>


.font_N{
  font-family: "Nightmare Pills";
  font-size: clamp(12px, 2vw, 16px);
}

a:link {
  color: #fafafa;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #fafafa;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #212020;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #c51515;
  background-color: transparent;
  text-decoration: underline;
}

.animation{
  animation: timer 16s;
}
@keyframes timer {
  from{width: 100%}
  to{ width: 0%}
}
.loader{
  position: absolute;
  height: 5px;
  background: #9D00FF;
  padding: 0;
  bottom: 0px;
  transform: translateX(-75px);
  border-radius: 0 0 8px 8px;
}

</style>