<template>

  <v-contain>
    <v-row justify="center">
      <v-col align="center">
        <v-img class="loaderIcon animation_loader" :src="require('../assets/Roulette/ui/Icons/Hunt_Logomark_White.png')">
        </v-img>
      </v-col>
    </v-row>
  </v-contain>

</template>

<script>
export default {
  name: "test.vue"
}
</script>

<style lang="scss" scoped>

.loaderIcon{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 20vw;
  max-width: 250px;
}
.animation_loader{
  animation: rotor 2.5s infinite;
}

@keyframes rotor {
  0%{ transform: translate(-50%, -50%) rotate(-180deg); opacity: 100%}
  50% {filter: blur(3px) }
  80%{ transform: translate(-50%, -50%) rotate(180deg); }
  100%{ opacity: 0%}
}



</style>