<template>
  <v-container id="view" class="pb-16 d-flex  flex-wrap justify-center" width="100%" height="100%">
  <span @click="drawer = !drawer" :class="{highlighting: loading}"  class="float-left mb-n12 mt-n4 spacer title_roulette text-center d-inline-block">Hunt Roulette</span>
    <!--
  <v-img max-width="40px" class="ml-16 float-right" :src="require('../assets/Roulette/ui/Hunt_Logomark_White.png')"></v-img>
  </div>
  -->
    <!--
    <v-alert class="alert_reminder label_text snackbar_s"color="grey darken-4"  type="info" dismissible transition=""><div class="statement">
      Disclaimer:<br>
      All graphical elements used in this project representing
      <a target="_blank" href="https://huntshowdown.com">Hunt: Showdown</a> are copyright of the
      <a target="_blank" href="https://www.crytek.com/">Crytek group</a>.
      </div>
    </v-alert>
    -->
    <customAlert></customAlert>
    <v-navigation-drawer
        fixed
        clipped
        v-model="drawer"
        class="nav_drawer"
        color="#4a4a4a"
    >
      <v-container class="justify-center">
        <v-row justify="center" class="">
          <v-col align="center">
            <span class="spacer text-no-wrap">Settings</span>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-divider></v-divider>

        <v-row class="rel_cl mt-1">
          <span class="label_text ml-4 mt-3">Require Melee</span>
          <v-switch
              :ripple=false
              v-model="melee"
              color="#701616"
              class="switch_cl"
          ></v-switch>
        </v-row>

        <v-row class="mt-n4">
          <span class="label_text ml-4 mt-3">Require Medkit
          </span>
          <v-switch
              :ripple=false
              v-model="medkit"
              color="#701616"
              class="switch_cl "
          ></v-switch>
        </v-row>

        <v-row class="mt-n4">
          <span class="label_text ml-4 mt-3">Vitality Shot</span>
          <v-switch
              :ripple=false
              v-model="vit_shot"
              color="#701616"
              class=" switch_cl"
          ></v-switch>
        </v-row>

        <v-row class="mb-1 mt-n4">
          <span class="label_text ml-4 mt-3">Quartermaster</span>
          <v-switch
              :ripple=false
              v-model="quartermaster"
              color="#701616"
              class=" switch_cl"
          ></v-switch>
        </v-row>

        <v-row class="mb-1 mt-n4">
          <span class="label_text ml-4 mt-3">No Dual wield</span>
          <v-switch
              :ripple=false
              v-model="disable_dualies"
              color="#701616"
              class="switch_cl"
          ></v-switch>
        </v-row>

        <v-divider></v-divider>
        <v-row justify="center">
          <v-col align="center">
            <span class="label_text">Tools</span>
            <v-slider
                v-model="tool_slider"
                track-color="#661111"
                ticks="always"
                inverse-label tick-size="1"
                :tick-labels="[0,1,2,3,4]"
                color="#661111"
                class="label_text slider_w "
                max="4">
            </v-slider>
          </v-col>
        </v-row>

        <v-divider class="mt-4 mb-1"></v-divider>

        <v-row class="justify-center ">
          <v-col align="center">
            <span class="label_text">Consumables</span>
            <v-slider
                v-model="consumable_slider"
                track-color="#661111"
                ticks="always"
                inverse-label tick-size="1"
                :tick-labels="[0,1,2,3,4]"
                color="#661111"
                class="mb-3 label_text slider_w"
                max="4">
            </v-slider>
          </v-col>
        </v-row>

        <v-divider class="mt-1 mb-2"></v-divider>

            <v-row class="justify-center">
              <v-col align="center">
                <span class="label_text">Bloodlinerank</span>
                <v-slider
                    :disabled="bl_dis"
                    v-model="bloodline_rank"
                    track-color="#661111"
                    color="#661111"
                    class="mb-n8 label_text slider_w"
                    max="100"></v-slider>
                <span class="label_text mt-n16 mb-3">{{ bloodline_rank }}</span>
              </v-col>
            </v-row>

        <v-divider class="mt-1 mb-2"></v-divider>

        <v-row justify="center">
          <v-col align="center">
            <v-dialog
                persistent
                hide-overlay
                v-model="dialog"
                transition="dialog-top-transition"
                max-width="65vw"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" height="100%" min-height="60px" width="95%" class="generate" >
                  <span class="btn_text">Exclude Items</span>
                </v-btn>
              </template>

              <v-card color="rgba(255, 255, 255, 0.11)" class="noBar overflow-x-hidden rounded-lg" height="80vh" width="65vw">

                <v-tabs fixed-tabs dark class="rounded-0"  v-model="tab" background-color="transparent" centered>
                  <v-tab href="#tab-1" class="label_text">Guns</v-tab>
                  <v-tab href="#tab-2" class="label_text">Consumables</v-tab>
                  <v-tab href="#tab-3" class="label_text">Tools</v-tab>
                  <!-- <v-tab href="#tab-4" class="label_text">Ammo Types</v-tab> -->
                  <v-tabs-items id="custom-tab-items" class="noBar" v-model="tab">
                    <v-tab-item  value="tab-1">

                      <v-row justify="space-around">
                        <v-tooltip top open-delay="10">
                          <template v-slot:activator="{ on }">
                            <v-btn elevation="0" v-on="on" class="independent_btn mt-8" @click="reset_selection_guns">Reset</v-btn>
                          </template>
                          <span>Restore default</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon dark  v-on="on" class="mt-8 icon_effect" >fas fa-info-circle</v-icon>
                          </template>
                          <span class="text-wrap tooltip_text" >Use website search to find a specific item [CTRL+F]</span>
                        </v-tooltip>
                      </v-row>
                      <v-row justify="center">

                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-row justify="center">

                            <v-card-title class="card_title">Excluded [{{unselected_guns.length}}]:</v-card-title>
                          </v-row>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in unselected_guns"
                                :key="item.name"
                                @click="removeFromNotRoll(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N unselected">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>

                      <v-divider class="mt-8" dark></v-divider>

                      <v-row justify="center">
                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-card-title class=" justify-center card_title">Selected [{{rollable_guns.length}}]:</v-card-title>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in rollable_guns"
                                :key="item.name"
                                @click="removeFromRoll(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <v-tab-item value="tab-2">
                      <v-row justify="space-around">
                        <v-tooltip top open-delay="10">
                          <template v-slot:activator="{ on }">
                            <v-btn color="rgb(17, 17, 17)" v-on="on" class="independent_btn mt-8" @click="reset_selection_consumables">Reset</v-btn>
                          </template>
                          <span>Restore default</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon dark  v-on="on" class="mt-8 icon_effect" >fas fa-info-circle</v-icon>
                          </template>
                          <span class="text-wrap tooltip_text" >Use website search to find a specific item [CTRL+F]</span>
                        </v-tooltip>
                      </v-row>
                      <v-row justify="center">

                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-row justify="center">
                            <v-card-title class="card_title">Excluded [{{excluded_cons.length}}]:</v-card-title>
                          </v-row>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in excluded_cons"
                                :key="item.name"
                                @click="removeFromNotRollCons(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N unselected">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>

                      <v-divider class="mt-8" dark></v-divider>

                      <v-row justify="center">
                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-card-title class=" justify-center card_title">Selected [{{rollabe_cons.length}}]:</v-card-title>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in rollabe_cons"
                                :key="item.name"
                                @click="removeCons(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>

                    </v-tab-item>

                    <v-tab-item value="tab-3">
                      <v-row justify="space-around">
                        <v-tooltip top open-delay="10">
                          <template v-slot:activator="{ on }">
                            <v-btn color="rgb(17, 17, 17)" v-on="on" class="independent_btn mt-8" @click="reset_selection_tools">Reset</v-btn>
                          </template>
                          <span>Restore default</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon dark  v-on="on" class="mt-8 icon_effect" >fas fa-info-circle</v-icon>
                          </template>
                          <span class="text-wrap tooltip_text" >Use website search to find a specific item [CTRL+F]</span>
                        </v-tooltip>
                      </v-row>
                      <v-row justify="center">

                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-row justify="center">
                            <v-card-title class="card_title">Excluded [{{excluded_tools.length}}]:</v-card-title>
                          </v-row>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in excluded_tools"
                                :key="item.name"
                                @click="removeFromNotRollTools(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N unselected">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>

                      <v-divider class="mt-8" dark></v-divider>

                      <v-row justify="center">
                        <v-col xl="10" lg="10" md="10" sm="10">
                          <v-card-title class=" justify-center card_title">Selected [{{rollabe_tools.length}}]:</v-card-title>
                          <v-chip-group dark column>
                            <v-chip
                                v-for="(item, index) in rollabe_tools"
                                :key="item.name"
                                @click="removeTools(item, index)"
                                class="font_N"
                                :ripple="false"
                            >
                              <span class="font_N">{{ item.name }}</span>
                            </v-chip>

                          </v-chip-group>
                        </v-col>
                      </v-row>

                    </v-tab-item>



                    <v-tab-item disabled value="tab-4">

                      <v-row justify="center">
                        <v-col align="center" class="mt-16">
                          <span class="spacer">WIP</span>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                  </v-tabs-items>
                </v-tabs>


              </v-card>
              <v-app-bar class="rounded-b-lg" dark bottom>
                <v-spacer></v-spacer>
                <v-btn color="rgb(14, 14, 14)" @click="dialog = false" class="independent_btn">
                  Done
                </v-btn>
                <v-spacer></v-spacer>
              </v-app-bar>

            </v-dialog>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row align="center">
          <span class="label_text ml-4 mt-n1">More Options:</span>
          <v-switch
              :ripple=false
              v-model="fun_mode"
              color="#701616"
              class=" switch_cl"
          ></v-switch>
        </v-row>
        <div v-if="fun_mode" class="outline rounded-lg">
        <!--<v-divider v-if="fun_mode" class="mt-1 mb-1"></v-divider>-->

          <v-row justify="center mt-2">
            <span class="label_text ml-4 mt-n1">Experimental!</span>
            <span class="label_text_side ml-4 mt-n1 text-center text-decoration-underline">made with the help of my good friend FabFab.</span>
          </v-row>


        <v-row justify="center" v-if="fun_mode" class="mr-1 ml-1">


          <v-col align="center">
            <span class="label_text">Fun level</span>
            <v-slider
                v-model="funlevel"
                track-color="#661111"
                color="#661111"
                class="mb-n8 label_text slider_w"
                max="10"></v-slider>
            <span class="label_text mt-n16 mb-3">{{ funlevel }}</span>
          </v-col>
        </v-row>
        <v-divider v-if="fun_mode" class="mt-1 mb-1"></v-divider>
        <v-row v-if="fun_mode" class="justify-center mr-1 ml-1">
          <v-col align="center">
            <span class="label_text">Tryhard level</span>
            <v-slider
                v-model="tryhard_lvl"
                track-color="#661111"
                color="#661111"
                class="mb-n8 label_text slider_w"
                max="10"></v-slider>
            <span class="label_text mt-n16 mb-3">{{ tryhard_lvl }}</span>
          </v-col>
        </v-row>
        </div>

      </v-container>

    </v-navigation-drawer>

    <mist class="loader mt-n3" v-if="loading"></mist>
    <test class="loader2" v-if="loading"></test>

    <v-col xl="8" lg="8" md="12" sm="12" xs="12" align="center" class="mt-8 mb-n16">
      <v-card  max-width="75vh" elevation="0" class="" :ripple="false" >

        <!-- @click="overlay = !overlay" -->
        <v-overlay absolute class="" :value="overlay">
          <!-- Button for alternative ListView -->
          <v-btn v-if="false" elevation="0" dark color="transparent" light class="listView" @click="openList">
            <v-icon >fas fa-list</v-icon>
          </v-btn>
          <div>


                    <div class="loadout_text mt-5">Weapons:</div>

            <v-container wrap class="justify-center ml-8">

              <v-flex class="xl7 lg7 md8 sm9 xs">
                <v-row align="center" class="justify-center">
                  <v-col align="center" xl="11" lg="11" md="11" sm="11" xs="11">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img contain v-on="on" class="" width="45vw"
                               v-bind:src="require('../assets/Roulette/Guns/' + gun1.img)">
                        </v-img>
                      </template>
                      <span v-if="gun1.name">{{ gun1.name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col xl="1" lg="1" md="1" sm="1" xs="1" class="justify-center">
                    <v-row>
                      <!-- Ammo -->
                      <v-tooltip  open-delay="10" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-img v-if="gun1.ammo.amount > 0" contain v-on="on" class="ammo_img"
                                 v-bind:src="require('../assets/Roulette/ammotypes/' + ammo1_gun1.img)">
                          </v-img>
                        </template>
                        <span v-if="ammo1_gun1.name">{{  ammo1_gun1.name }}</span>
                      </v-tooltip>
                      <!-- Ammo -->
                      <v-tooltip open-delay="10" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-img v-if="gun1.ammo.amount > 1" contain v-on="on" class="ammo_img"
                                 v-bind:src="require('../assets/Roulette/ammotypes/' + ammo2_gun1.img)">
                          </v-img>
                        </template>
                        <span v-if="ammo2_gun1.name">{{ ammo2_gun1.name }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
                  <v-row  align="center" class="justify-center">
                    <v-col align="center" xl="11" lg="11" md="11" sm="11" xs="11">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on }">
                        <v-img  contain v-on="on" class="" width="45vw"
                               v-bind:src="require('../assets/Roulette/Guns/' + gun2.img)">
                        </v-img>
                      </template>
                      <span>{{ gun2.name }}</span>
                    </v-tooltip>
                    </v-col>
                    <v-col xl="1" lg="1" md="1" sm="1" xs="1" class="justify-center">
                      <v-row>
                    <!-- Ammo -->
                      <v-tooltip  open-delay="10" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-img v-if="gun2.ammo.amount > 0" contain v-on="on" class="ammo_img"
                                 v-bind:src="require('../assets/Roulette/ammotypes/' + ammo1_gun2.img)">
                          </v-img>
                        </template>
                        <span v-if="ammo1_gun2.name">{{  ammo1_gun2.name }}</span>
                      </v-tooltip>
                        <!-- Ammo -->
                        <v-tooltip open-delay="10" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-img v-if="gun2.ammo.amount > 1" contain v-on="on" class="ammo_img"
                                   v-bind:src="require('../assets/Roulette/ammotypes/' + ammo2_gun2.img)">
                            </v-img>
                          </template>
                          <span v-if="ammo2_gun2.name">{{ ammo2_gun2.name }}</span>
                        </v-tooltip>
                      </v-row>
                      </v-col>
                </v-row>
              </v-flex>
            </v-container>

            <div v-if="tools_arr.length > 0" class="loadout_text mb-3">Tools:</div>

            <v-container v-if="tools_arr.length > 0" class="justify-space-around mb-n4">
              <v-flex class="xl7 lg7 md12 sm12 xs">
                <v-row justify="center" class="ml-2 mr-2 mt-n10 mb-n8">
                  <v-col v-if="tools_arr.length > 0" xl="3" lg="3" md="3" sm="3" xs="3">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on }">
                      <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                             v-bind:src="require('../assets/Roulette/Tools/' + tools_arr[0].img)">
                      </v-img>
                      </template>
                      <span>{{ tools_arr[0].name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="tools_arr.length > 1" xl="3" lg="3" md="3" sm="3" xs="3">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on }">
                      <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                             v-bind:src="require('../assets/Roulette/Tools/' + tools_arr[1].img)">
                      </v-img>
                      </template>
                      <span>{{ tools_arr[1].name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="tools_arr.length > 2" xl="3" lg="3" md="3" sm="3" xs="3">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on }">
                      <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                             v-bind:src="require('../assets/Roulette/Tools/' + tools_arr[2].img)">
                      </v-img>
                      </template>
                      <span>{{ tools_arr[2].name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="tools_arr.length > 3" xl="3" lg="3" md="3" sm="3" xs="3">
                    <v-tooltip open-delay="10" bottom>
                      <template v-slot:activator="{ on }">
                      <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                             v-bind:src="require('../assets/Roulette/Tools/' + tools_arr[3].img)">
                      </v-img>
                      </template>
                      <span>{{ tools_arr[3].name }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-flex>
            </v-container>

            <div v-if="cons_arr.length > 0" class="loadout_text mb-3">Consumables:</div>

            <v-container v-if="cons_arr.length > 0" class="justify-space-around">
              <v-flex class="xl7 lg7 md12 sm12 xs">
              <v-row justify="center" class="ml-2 mr-2 mt-n10" >
                <v-col v-if="cons_arr.length > 0" xl="3" lg="3" md="3" sm="3" xs="3">
                  <v-tooltip open-delay="10" bottom>
                    <template v-slot:activator="{ on }">
                    <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                           v-bind:src="require('../assets/Roulette/Consumables/'+cons_arr[0].img)">
                    </v-img>
                    </template>
                    <span>{{ cons_arr[0].name }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="cons_arr.length > 1" xl="3" lg="3" md="3" sm="3" xs="3">
                  <v-tooltip open-delay="10" bottom>
                    <template v-slot:activator="{ on }">
                    <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                           v-bind:src="require('../assets/Roulette/Consumables/'+cons_arr[1].img)">
                    </v-img>
                    </template>
                    <span>{{ cons_arr[1].name }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="cons_arr.length > 2" xl="3" lg="3" md="3" sm="3" xs="3">
                  <v-tooltip open-delay="10" bottom>
                    <template v-slot:activator="{ on }">
                    <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                           v-bind:src="require('../assets/Roulette/Consumables/'+cons_arr[2].img)">
                    </v-img>
                    </template>
                    <span>{{ cons_arr[2].name }}</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="cons_arr.length > 3" xl="3" lg="3" md="3" sm="3" xs="3">
                  <v-tooltip open-delay="10" bottom>
                    <template v-slot:activator="{ on }">
                    <v-img v-on="on" :class="{miniSize: $vuetify.breakpoint.xs }" class="tools_show_mate mx-auto"
                           v-bind:src="require('../assets/Roulette/Consumables/'+cons_arr[3].img)">
                    </v-img>
                    </template>
                    <span>{{ cons_arr[3].name }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              </v-flex>
            </v-container>
            <v-layout class="justify-center">
            </v-layout>
          </div>
        </v-overlay>
        <v-img :class="{Avatar: loading}" contain v-bind:src="require('../assets/Roulette/ui/'+changeImage())"/>
      </v-card>
    </v-col>
    <v-col xl="4" lg="6"  md="12" sm="12" xs="12"
           v-if="$vuetify.breakpoint.mdAndDown"
           align="center"
           class="ma-xl-auto my-xs-auto mt-8 justify-end">
      <v-row class="mb-1">
        <v-col class="" align="center">
          <v-btn height="100%" class="generate mt-3 mb-n1 " @click="shuffle">
            <span class="pt-xs-2">Shuffle</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  <v-btn
      v-if="$vuetify.breakpoint.lgAndUp"
      height="100%" class="generate2 mr-n8" @click="shuffle">
    <span class="">Shuffle</span>
  </v-btn>
  </v-container>
</template>
<script>

import mist from '@/components/mist_loader.vue'
import test from '@/components/test.vue'
import customAlert from '@/components/customAlert'

const guns_data = require("../assets/Roulette/data/guns.json")
const ammo_types = require("../assets/Roulette/data/ammo_types.json")
const ammo_families = require("../assets/Roulette/data/ammo_families.json")
const tools_data = require("../assets/Roulette/data/tools.json")
const consumables_data = require("../assets/Roulette/data/consumables.json")

export default {
  name: "Roulette.vue",
  components: {
    mist,
    test,
    customAlert,
  },

  props: {
    anim: Boolean,
  },

  mounted() {
    this.rollable_guns = Object.assign([], guns_data)
    this.rollabe_cons = Object.assign([], consumables_data)
    this.rollabe_tools = Object.assign([], tools_data)
  },

  data: () => ({
    overlay: false, //prod = false
    drawer: true,
    group: false,
    expand: false,
    bl_dis: false,
    dialog: false,
    disable_dualies: false,
    snack: true,

    tab: null,

    rollabe_tools: [],
    excluded_tools: [],

    unselected_guns: [],
    rollable_guns: [],

    rollabe_cons: [],
    excluded_cons: [],

    tool_slider: 4,
    consumable_slider: 4,
    bloodline_rank: 100,
    medkit: false,
    melee: false,
    vit_shot: false,
    loading: false,
    quartermaster: false,

    fun_mode: false,
    funlevel: 0,
    tryhard_lvl: 0,

    hunter: "Hunter_1.png",
    hunter2: "Hunter_2.png",
    hunter3: "Hunter_3.png",

      gun1: {
        name: null,
        img: null,
        gun_size: null,
        ammo: {
          amount: 0,
          type1: null,
        },
      },

    gun2: {
      name: null,
      img: null,
      gun_size: null,
      ammo: {
        amount: 0,
        type1: null,
      },
    },

    ammo1_gun1: {
      "name": "",
      "cost": 0,
      "img": 'buckshot.png'
    },
    ammo2_gun1: {
      "name": "",
      "cost": 0,
      "img": 'bolt.png'
    },

    ammo1_gun2: {
      "name": "",
      "cost": 0,
      "img": null
    },
    ammo2_gun2: {
      "name": "",
      "cost": 0,
      "img": null
    },

    tools_arr: [],
    cons_arr: [],
  }),

  methods: {
    scrollToTop(){
     //window.scrollTo(0,0)
      document.getElementById('view').scrollIntoView({ behavior: 'smooth' });
    },

    //todo
    async shuffle(){

      if(this.rollabe_tools.length <= 4){
        this.tool_slider = this.rollabe_tools.length
      }
      if(this.rollabe_cons.length <= 4){
        this.consumable_slider = this.rollabe_cons.length
      }


      this.loading = true
      this.overlay = false
      this.drawer = false
      this.scrollToTop()

      let filtered_gun_data
      if(this.disable_dualies){
        if(this.fun_mode){
          if(this.funlevel > 0){
            filtered_gun_data = this.rollable_guns.filter( item => {
              return item.fun_lvl >= this.funlevel
            })
          }else if(this.tryhard_lvl > 0){
            filtered_gun_data = this.rollable_guns.filter( item => {
              return item.tryhard_lvl >= this.tryhard_lvl
            })
          }
        }else{
          filtered_gun_data = this.rollable_guns.filter( item => {
            return item.unlock_lvl <= this.bloodline_rank
          })
        }
        filtered_gun_data = filtered_gun_data.filter(item => {
            return !(item.name.includes('Dual') || item.name.includes('Pair'))
        })
      }else{
        if(this.fun_mode){
          if(this.funlevel > 0){
            filtered_gun_data = this.rollable_guns.filter( item => {
              return item.fun_lvl >= this.funlevel
            })
          }else if(this.tryhard_lvl > 0){
            filtered_gun_data = this.rollable_guns.filter( item => {
              return item.tryhard_lvl >= this.tryhard_lvl
            })
          }
        }else{
          filtered_gun_data = this.rollable_guns.filter( item => {
            return item.unlock_lvl <= this.bloodline_rank
          })
        }
      }


      //console.table(filtered_gun_data)

      await this.resolveGun1(filtered_gun_data)
      await this.resolveGun2(filtered_gun_data)
      await this.rollTools()
      await this.rollCons()
      //console.log('return to normal?')
      setTimeout( () => { this.loading = false, this.overlay = true, this.drawer = true}, 2500)
    },

    //changes Hunter image based on mode
    changeImage(){
      if(this.fun_mode){
        if(this.funlevel >= 0 && this.tryhard_lvl <= 0){
          return this.hunter2
        }else{
          return this.hunter3
        }
      }else {
        return this.hunter
      }
    },

    removeFromRoll(item, index){
      this.rollable_guns.splice(index,1)
      this.unselected_guns.push(item)
    },

    removeCons(item, index){
      //if(this.rollabe_cons.length > 1){
        this.rollabe_cons.splice(index,1)
        this.excluded_cons.push(item)
      //}
      //console.table(this.rollable_guns)
    },

    removeTools(item, index){
      //if(this.rollabe_tools.length > 1){
        this.rollabe_tools.splice(index,1)
        this.excluded_tools.push(item)
      //}
      //console.table(this.rollable_guns)
    },

    removeFromNotRoll(item, index){
      this.unselected_guns.splice(index,1)
      this.rollable_guns.push(item)
      //console.table(this.rollable_guns)
    },

    removeFromNotRollCons(item, index){
      this.excluded_cons.splice(index,1)
      this.rollabe_cons.push(item)
      //console.table(this.rollable_guns)
    },

    removeFromNotRollTools(item, index){
      this.excluded_tools.splice(index,1)
      this.rollabe_tools.push(item)
    },

    reset_selection_guns(){
      //console.table(this.rollable_guns)
      //console.table(guns_data)
      this.rollable_guns = Object.assign([], guns_data)
      this.unselected_guns = []
    },

    reset_selection_consumables(){
      this.rollabe_cons = Object.assign([], consumables_data)
      this.excluded_cons = []
    },

    reset_selection_tools(){
      this.rollabe_tools = Object.assign([], tools_data)
      this.excluded_tools = []
    },

    getRandomInt(max){
      let x = Math.floor(Math.random() * max)
      //console.log(x)
      return x
    },

    async resolveGun1(filtered_gun_data){
      this.gun1 = filtered_gun_data[this.getRandomInt(filtered_gun_data.length)]
      if(this.gun1.ammo.amount > 0){
        await this.resolveGun1_ammo()
      }
    },

    async resolveGun2(filtered_gun_data){
      if(this.quartermaster){
        let g_size = (5 - this.gun1.gun_size)
        let new_gun_collection = filtered_gun_data.filter( item => item.gun_size <= g_size)
        this.gun2 = new_gun_collection[this.getRandomInt(new_gun_collection.length)]
      }else{
        let g_size = (4 - this.gun1.gun_size)
        let new_gun_collection = filtered_gun_data.filter( item => item.gun_size <= g_size)
        this.gun2 = new_gun_collection[this.getRandomInt(new_gun_collection.length)]
      }
      //console.table('GUN_Nr2\n'+JSON.stringify(this.gun2,null,4))
      if(this.gun2.ammo.amount > 0){
        await this.resolveGun2_ammo()
      }
    },

    resolveGun1_ammo(){
      if(this.gun1.ammo.amount > 0) {
        let x1 = ammo_families[this.gun1.ammo.type1]
        this.ammo1_gun1 = ammo_types[x1[this.getRandomInt(x1.length)]]
      }
      if(this.gun1.ammo.amount > 1 && !(this.gun1.name.includes("LeMat") || this.gun1.name.includes("Drill"))){
        let x2 = ammo_families[this.gun1.ammo.type1]
        this.ammo2_gun1 = ammo_types[x2[this.getRandomInt(x2.length)]]
      }else if(this.gun1.name.includes("LeMat") || this.gun1.name.includes("Drill")) { //if(this.gun1.ammo.hasOwnProperty('type2'))
        let x2 = ammo_families[this.gun1.ammo.type2]
        this.ammo2_gun1 = ammo_types[x2[this.getRandomInt(x2.length)]]
      }
    },

    resolveGun2_ammo(){
      if(this.gun2.ammo.amount > 0){
        let x1 = ammo_families[this.gun2.ammo.type1]
        this.ammo1_gun2 = ammo_types[x1[this.getRandomInt(x1.length)]]
        if(this.gun2.ammo.amount > 1 && !(this.gun2.name.includes("LeMat") || this.gun1.name.includes("Drill"))){
          let x2 = ammo_families[this.gun2.ammo.type1]
          this.ammo2_gun2 = ammo_types[x2[this.getRandomInt(x2.length)]]
        }else if(this.gun2.name.includes("LeMat")  || this.gun2.name.includes("Drill")){
          let x2 = ammo_families[this.gun2.ammo.type2]
          this.ammo2_gun2 = ammo_types[x2[this.getRandomInt(x2.length)]]
        }
      }
    },

    rollTools(){
      this.tools_arr = []
      if(this.melee){
        let melee_tool = this.rollabe_tools.filter( item => item.name.includes('Knife') || item.name.includes('Dusters'))
        if(melee_tool.length > 0){
          this.tools_arr.push(melee_tool[this.getRandomInt(melee_tool.length)])
        }else{
          this.tools_arr.push(tools_data[6])
        }
      }
      console.log(JSON.stringify(this.tools_arr,null,4))
      if(this.medkit){
        this.tools_arr.push(tools_data[2])
      }
      //console.log('length here: '+this.tools_arr.length)
      //console.log('Diff here: '+ (this.tool_slider - this.tools_arr.length))

      if(this.tools_arr.length < this.tool_slider){
        let k = this.tool_slider - this.tools_arr.length
        let i = 0
        //console.log('Slider_setting: '+this.tool_slider)
        for(i; i < k; i++){
          this.tools_arr.push(this.getRandomTool())
        }
      }
      console.log(JSON.stringify(this.tools_arr,null,4))
    },

    getRandomTool(){
      let remainingtools = this.rollabe_tools.filter( item => {
        return !this.tools_arr.includes(item)
      })
      if(this.melee){
        remainingtools = remainingtools.filter( item => {
          return !item.name.includes('Knife') && !item.name.includes('Dusters')
        })
        console.log(JSON.stringify(remainingtools,null,4))
      }
      //debug duplicates here
      //console.table(remainingtools)
      //console.table(tools_data)
      return remainingtools[this.getRandomInt(remainingtools.length)]
    },

    rollCons(){
      this.cons_arr = []
      if(this.vit_shot){
        this.cons_arr.push(consumables_data[5])
        //console.log(JSON.stringify(this.cons_arr,null,4))
      }
      if(this.cons_arr.length < this.consumable_slider){
        let k = this.consumable_slider - this.cons_arr.length
        let i = 0
        //console.log('Slider_setting: '+this.consumable_slider)
        for(i; i < k; i++){
          this.cons_arr.push(this.getRandomCons())
        }
      }
    },

    getRandomCons(){
      let new_cons_Arr = this.rollabe_cons.filter( item => {
        return item.unlock_lvl <= this.bloodline_rank
      })
      //console.table(new_cons_Arr)
      return new_cons_Arr[this.getRandomInt(new_cons_Arr.length)]
    },

  },

  watch: {

    rollabe_tools(){
      if(this.rollabe_tools.length < 4){
        this.tool_slider = this.rollabe_tools.length
      }
    },

    rollabe_cons(){
      if(this.rollabe_cons.length < 4){
        this.consumable_slider = this.rollabe_cons.length
      }
    },


    funlevel(){
      if(this.funlevel > 0){
        this.tryhard_lvl = 0
        this.bloodline_rank = 100
      }
    },
    tryhard_lvl(){
      if(this.tryhard_lvl > 0){
        this.funlevel = 0
        this.bloodline_rank = 100
      }
    },
    bloodline_rank(){
      if(this.bloodline_rank < 100){
        this.funlevel = 0
        this.tryhard_lvl = 0
      }
    },
    vit_shot(){
      if(this.vit_shot && this.consumable_slider < 1){
        this.consumable_slider++
      }
    },
    melee(){
      if(this.medkit && this.melee && this.tool_slider < 2){
        this.tool_slider++
      }else if(this.melee && this.tool_slider < 1){
        this.tool_slider++
      }
    },
    medkit(){
      if(this.medkit && this.melee && this.tool_slider < 2){
        this.tool_slider++
      }else if(this.medkit && this.tool_slider < 1){
        this.tool_slider++
      }
    },
    tool_slider(){
      console.log(this.tool_slider)
      if(this.tool_slider < 2 && this.medkit && this.melee){
        this.medkit = false
        this.melee = false
      }
      if(this.tool_slider < 1 && (this.melee || this.medkit)){
        this.melee = false
        this.medkit = false
      }
    },

    disable_dualies(){
      let x
      if(this.disable_dualies){
        x = this.rollable_guns.filter( item => {
          return (item.name.includes('Dual') || item.name.includes('Pair'))
        })

        x = x.filter( item => {
          return !this.unselected_guns.includes(item)
        })

        this.unselected_guns.push(x)

        this.rollable_guns = this.rollable_guns.filter( item => {
          return !(item.name.includes('Dual') && item.name.includes('Pair'))
        })
      }else{
        x = this.unselected_guns.filter( item => {
          return (item.name.includes('Dual') || item.name.includes('Pair'))
        })
        this.unselected_guns =  this.unselected_guns.filter( item => {
          return !(item.name.includes('Dual') && item.name.includes('Pair'))
        })
        this.rollable_guns.push(x)
      }

      /*
      let remainingtools = tools_data.filter( item => {
        return !this.tools_arr.includes(item); console.log(item.name)
      })
      */
    },
    }
}
</script>
<style lang="scss" scoped>
@import '../assets/roulette.css';

.theme--light.v-card{
  background-color: transparent;
}

.slider_w{
  max-width: 300px;
}

.label_text{
  font-family: 'Nightmare Pills';
  color: #c5c5c5;
  font-size: 25px;
}

.label_text_side{
  font-family: 'Nightmare Pills';
  color: #c5c5c5;
  font-size: 18px;
}


.theme--light.ovl_open{
  background-color: darkgrey;
  opacity: 20%;
  margin-bottom: 1REM;
}

.theme--light.v-overlay{
  margin-bottom: 1REM;
  border-radius: 20px;
}

.theme--light.v-card{
  background-color: Transparent;
  border-radius: 30px;
}

.theme--light.sm2{
  flex-direction: column;
}

</style>