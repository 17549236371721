<template>
  <v-app>
    <v-main class="backgrnd">
      <fog v-if="anim" class="fog">
      </fog>
      <router-view :anim="anim" class="views"/>
      <custom-footer></custom-footer>
    </v-main>

  </v-app>
</template>

<script>
import customFooter from '@/components/customfooter'
import fog from '@/components/fog_background.vue'

export default {
  name: 'App',
  components: {
    fog,
    customFooter,
  },

  data: () => ({
    anim: true
  }),
};
</script>
<style lang="scss">
.fog{
  height: 100%;
  width: 100%;
}

.backgrnd{
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body::-webkit-scrollbar {
  display: none;
}

.views{
  z-index: 20;
}

</style>
