import Vue from 'vue'
import VueRouter from 'vue-router'
import Roulette from "../views/Roulette";
import Totti from "../views/Totti"
import NotFound from "../views/404"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Roulette',
    component: Roulette
  },
  {
    path: '/sirthorstentv',
    name: 'Totti',
    component: Totti
  },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
