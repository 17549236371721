<template>
  <v-alert :value="toggle" class="alert_reminder label_text snackbar_s" color="grey darken-4" max-width="22vw" min-width="350px"  transition="slide-x-reverse-transition">
    <template v-slot:prepend>
      <v-icon color="#fafafa" class="mr-4">mdi-information-outline</v-icon>
    </template>
    <div class="statement">
    Disclaimer:<br>
    All graphical elements used in this project representing
    <a target="_blank" href="https://huntshowdown.com">Hunt: Showdown</a> are copyright of the
    <a target="_blank" href="https://www.crytek.com/">Crytek group</a>.
  </div>
    <div class="animation loader"></div>
  </v-alert>
</template>

<script>
export default {
  name: "customAlert",

  mounted() {
    setTimeout( () => {
      this.toggle = false
      this.disableBanner()
    },7950)
  },

  data: () => ({
    toggle: localStorage.getItem('noBanner') ? false : true,
  }),

  methods: {
    disableBanner(){
      localStorage.setItem('noBanner', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.unselected{
  color: #650808;
}

.font_N{
  font-family: "Nightmare Pills";
  font-size: clamp(12px, 2vw, 16px);
}

a:link {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: red;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #753131;
  background-color: transparent;
  text-decoration: underline;
}

.animation{
  animation: timer 8s;
}
@keyframes timer {
  from{width: 100%}
  to{ width: 0%}
}
.loader{
  position: absolute;
  height: 5px;
  background: #3d3d3d;
  padding: 0;
  bottom: 0px;
  transform: translateX(-55px);
  border-radius: 0 0 10px 10px;
}
</style>