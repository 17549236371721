<template>
  <v-footer dark app>
    <p class="mx-auto mb-0" id="foot3r">&copy  <span class="font_N">{{footdate}} hunt-randomizer.de. All rights reserved.</span></p>
    <v-tooltip style="width:  clamp(300px, 20rem, 40vw) !important;" top open-delay="10" right close-delay="10000">
      <template v-slot:activator="{ on }">
        <p class="mb-0" v-on="on">
          <v-icon style="text-shadow: 0 0 21px #fff">fas fa-file-alt</v-icon>
        </p>
      </template>

      <div style="max-width: 23vw">
        <p class="text-decoration-underline">
          Hunt Randomizer v1.1.0
        </p>
        <br>
        <p class="text-decoration-underline">
          Latest changes:
        </p>
        <ul style="font-size: 20px">
          <li>Fixed the long disabled option to remove dual weild.</li>
          <li>Added new guns from patch the latest patches. (Drilling, Hammer, New Army Swift, Uppermat)</li>
        </ul>
        <br>
        <p class="text-decoration-underline">
          Future changes:
        </p>
        <ul style="font-size: 20px">
          <li>adding total loadout cost to UI
            <ul style="font-size: 17px">
              <li>
                Currently working on v2 of this randomizer with some awesome new features. 🤫
              </li>
            </ul>
          </li>
        </ul>
        <br>
        <p class="text-decoration-underline">What broke?</p>
        <ul style="font-size: 20px">
            <li>Hopefully <span class="text-decoration-underline text-h6">nothing</span>. If you find something, please let me know via mail.</li>
        </ul>
      </div>
    </v-tooltip>
    <v-tooltip style="width: clamp(300px, 20rem, 40vw)  !important;" top open-delay="10" right close-delay="10000">
      <template v-slot:activator="{ on }">
        <p class="mb-0 ml-4" v-on="on">
          <v-icon style="text-shadow: 0 0 18px #fff">fas fa-envelope-square</v-icon>
        </p>
      </template>
      <div style="max-width: 23vw">
        <p class="text-decoration-underline">
          Contact:
        </p>
        <ul style="font-size: 20px">
          <li>If you have any suggestions or request,<br>
            contact me via mail:
          <br>
            <span style="user-select: text" class="text-h6">die4trying.dev@gmail.com</span>
          </li>
        </ul>
      </div>
    </v-tooltip>
  </v-footer>
</template>

<script>
export default {
  name: "customFooter",

  data: () => ({
    footdate: new Date().getFullYear(),
  }),

    methods: {
      copyToClipboard(){
          navigator.clipboard.writeText('die4trying.dev@gmail.com');

      }
    }
}
</script>

<style scoped>
.font_N{
  font-family: "Nightmare Pills";
  font-size: clamp(12px, 2vw, 16px);
}
</style>